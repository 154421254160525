(function () {
    'use strict';

    angular.module('informaApp')
        .component('diseaseView', {
            templateUrl: 'components/disease-view/template.html',
            controller: ['$scope', DiseaseViewController],
            bindings: {
                diseases: '<',
            }
        });

    function DiseaseViewController($scope) {
        var vm = this;

        vm.activeDisease = null;
        vm.selectedDiseaseId = null;
        vm.filterHeight = 70;

        vm.onActiveDiseaseChange = function (id) {
            vm.activeDisease = _.find(vm.diseases, function (x) {
                return x.id === id;
            });

            $scope.$digest();
        };

        vm.onSelectDisease = function (id) {
            vm.selectedDiseaseId = {id: id};

            $scope.$digest();
        };

        this.$onInit = function () {
            vm.diseaseFilterOptions = vm.diseases.map(function (x) {
                return {
                    id: x.id,
                    name: x.name
                }
            });
        }
    }
})();